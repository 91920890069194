<template>
  <main>
    <!-- breadcrumb-area -->
    <section class="breadcrumb-area-two breadcrumb-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="breadcrumb-content text-center">
              <h6 class="title" style="font-size:  x-large">{{$t('Archive')}}</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- breadcrumb-area-end -->
    <section class="section section-team section-member my-5">
      <div class="container">
        <div class="page-actions">
          <div class="page-action-item action-share">
            <div class="d-flex align-items-center">
              <a class="text-primary facebook" @click="shareOnFacebook">
                <i class="ph ph-facebook-logo"></i>
              </a>
              <a class="text-primary twitter" @click="shareOnTwitter">
                <i class="ph ph-twitter-logo"></i>
              </a>
              <a class="text-primary whatsapp" @click="shareOnWhatsApp">
                <i class="ph ph-whatsapp-logo"></i>
              </a>
              <a class="text-primary telegram" @click="shareOnTelegram">
                <i class="ph ph-telegram-logo"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="booking-wrap">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="bOOKing-tab" data-bs-toggle="tab" data-bs-target="#bOOKing-tab-pane" type="button"
                          role="tab" aria-controls="bOOKing-tab-pane" aria-selected="true">
                    <i class="fa fa-newspaper"></i>{{ $t('News') }}
                  </button>
                </li>
                <li class="nav-item"  role="presentation">
                  <button class="nav-link" id="trips-tab" data-bs-toggle="tab" data-bs-target="#trips-tab-pane" type="button"
                          role="tab" aria-controls="trips-tab-pane" aria-selected="false">
                    <i class="fa fa-camera"></i> {{ $t('Photo gallery') }}
                  </button>
                </li>
              </ul>
              <div class="row" style="padding-top: 15px;">
                <div class="col-md-12">
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="bOOKing-tab-pane" role="tabpanel" aria-labelledby="bOOKing-tab" tabindex="0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="row fly-next-active justify-content-center">
                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in newsPost" :key="item.id">
                              <div class="fly-next-item">
                                <div class="fly-next-thumb">
                                  <router-link :to="{ path: '/old_news_detail', query: { slug: item.id,categoryDate: 'newsold'} }">
                                    <img class="fixed-image-list" v-if="!item.image" src="../../../assets/img/images/placeholder_size_2.jpg">
                                    <img class="fixed-image-list" v-else :src="'https://api.railway.kg/media/news/full/'+item.image" alt="">
                                  </router-link>
                                </div>
                                <div class="fly-next-content">
                                  <span> {{ formatDate(item.add_date) }}</span>
                                  <div style="height: 100px">
                                    <h4 class="title fly-next-content-title">{{
                                        item.title
                                      }}</h4>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                          <nav aria-label="Page navigation">
                            <ul class="pagination justify-content-center mt-4">
                              <li class="page-item">
                                <a class="page-link"
                                   href="#"
                                   @click="changePageNews(newsCurrentPage - 1)">
                                  {{ $t('Previous') }}
                                </a>
                              </li>
                              <li class="page-item" v-for="pageNumber in pageNumberNews" :key="pageNumber"
                                  :class="{active:newsCurrentPage == pageNumber || pageNumber === '....'}">
                                <a class="page-link"
                                   href="#" @click="changePageNews(pageNumber)">{{ pageNumber }}</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link"
                                   href="#"
                                   @click="changePageNews(newsCurrentPage +1)">
                                  {{ $t('NextNext') }}
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="trips-tab-pane" role="tabpanel" aria-labelledby="trips-tab"
                         tabindex="0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="row fly-next-active justify-content-center">
                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer"
                                 v-for="item in imagesPost" :key="item.id">
                              <div class="fly-next-item">
                                <div class="fly-next-thumb">
                                  <router-link
                                      :to="{ path: '/old_imagegalery_detail', query: { slug: item.id,categoryDate: 'oldalbum'} }">
                                    <img class="fixed-image-list" v-if="!item.image"
                                         src="../../../assets/img/images/placeholder_size_2.jpg">
                                    <img class="fixed-image-list" v-else :src="'https://api.railway.kg/media/gallery/albums/'+item.image" alt="">
                                  </router-link>
                                </div>
                                <div class="fly-next-content">
                                  <span>{{ formatDate(item.add_date) }}</span>
                                  <div style="height: 100px">
                                    <h4 class="title fly-next-content-title">{{
                                        getLocaleText(item.title_ky, item.title_ru, item.title_en)
                                      }}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <nav aria-label="Page navigation">
                            <ul class="pagination justify-content-center mt-4">
                              <li class="page-item">
                                <a class="page-link"
                                   href="#"
                                   @click="changePageImage(imageCurrentPage - 1)">
                                  {{ $t('Previous') }}
                                </a>
                              </li>
                              <li class="page-item" v-for="pageNumber in pageNumberImage" :key="pageNumber"
                                  :class="{active:imageCurrentPage == pageNumber || pageNumber === '....'}">
                                <a class="page-link"
                                   href="#" @click="changePageImage(pageNumber)">{{ pageNumber }}</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link"
                                   href="#"
                                   @click="changePageImage(imageCurrentPage +1)">
                                  {{ $t('NextNext') }}
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import {Tab} from 'bootstrap';
import getLocaleText from "@/locales/getLocaleText";
import axios from "axios";
import i18n from "@/local18";

export default {
  data() {
    return {
      currentUrl: window.location.href,
      News: [],
      ImageGalery: [],
      itemPerPage: 20,
      newsCurrentPage: 1,
      imageCurrentPage: 1,
    };
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        this.fetchNews();
        this.fetchImageGalery();
      }
    }
  },
  created() {
    this.fetchNews();
    this.fetchImageGalery();
  },
  computed: {
    newsPost() {
      const startPage = (this.newsCurrentPage - 1) * this.itemPerPage;
      const endPage = startPage + this.itemPerPage;

      return this.News.slice(startPage, endPage);
    },
    totalNews() {
      return Math.ceil(this.News.length / this.itemPerPage)
    },
    pageNumberNews() {
      let pageNumbers = []
      if (this.totalNews <= 7) {
        for (let i = 1; i <= this.totalNews; i++) {
          pageNumbers.push(i);
        }
      } else {
        if (this.newsCurrentPage <= 4) {
          pageNumbers = [1, 2, 3, 4, 5, "....", this.totalNews];
        } else if (this.newsCurrentPage <= this.totalNews - 3) {
          pageNumbers = [1, "....", this.newsCurrentPage - 2, this.newsCurrentPage - 1, this.newsCurrentPage, this.newsCurrentPage + 1, this.newsCurrentPage + 2, "....", this.totalNews];

        } else {
          pageNumbers = [1, "....", this.totalNews - 4, this.totalNews - 3, this.totalNews - 2, this.totalNews - 1, this.totalNews]
        }
      }
      return pageNumbers;
    },
    imagesPost() {
      const startPage = (this.imageCurrentPage - 1) * this.itemPerPage;
      const endPage = startPage + this.itemPerPage;
      return this.ImageGalery.slice(startPage, endPage);
    },
    totalImages() {
      return Math.ceil(this.ImageGalery.length / this.itemPerPage)
    },
    pageNumberImage() {
      let pageNumbers = []
      if (this.totalImages <= 7) {
        for (let i = 1; i <= this.totalImages; i++) {
          pageNumbers.push(i);
        }
      } else {
        if (this.imageCurrentPage <= 4) {
          pageNumbers = [1, 2, 3, 4, 5, "....", this.totalImages];
        } else if (this.imageCurrentPage <= this.totalImages - 3) {
          pageNumbers = [1, "....", this.imageCurrentPage - 2, this.imageCurrentPage - 1, this.imageCurrentPage, this.imageCurrentPage + 1, this.imageCurrentPage + 2, "....", this.totalImages];

        } else {
          pageNumbers = [1, "....", this.totalImages - 4, this.totalImages - 3, this.totalImages - 2, this.totalImages - 1, this.totalImages]
        }
      }
      return pageNumbers;
    },
  },
  methods: {
    shareOnTwitter() {
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnFacebook() {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnWhatsApp() {
      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    shareOnTelegram() {
      window.open(`tg://msg_url?url=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },

    changePageNews(page) {
      if (page >= 1 && page <= this.totalNews) {
        this.newsCurrentPage = page;
      }
    },
    changePageImage(page) {
      if (page >= 1 && page <= this.totalImages) {
        this.imageCurrentPage = page;
      }
    },
    async fetchNews() {
      try {
        const local = i18n.global.locale.value === 'kg' ? 'ky':'ru';
        const response = await axios.get(`newsold/?page=1&page_size=10000&search=${local}`);
        this.News = response.data.results
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    async fetchImageGalery() {
      try {
        const response = await axios.get(`oldalbum/?page=1&page_size=10000`);
        this.ImageGalery = response.data.results;
        console.log(response.data.results)
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    getLocaleText,
    getMainImage(images) {
      let mainImage = images.find(item => item.main_image);
      if (mainImage) {
        return mainImage.image_size_2
      } else {
        return images[0].image_size_2
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Bu tarih dilimine göre tarihi biçimlendirir
    }
  },
  mounted() {
    const triggerTabList = document.querySelectorAll('#myTab button')
    triggerTabList.forEach(triggerEl => {
      const tabTrigger = new Tab(triggerEl)

      triggerEl.addEventListener('click', event => {
        event.preventDefault()
        tabTrigger.show()
      })
    })
  }
}
</script>
