<template>
  <div class="col-md-8">
    <div class="page-actions">
      <div class="page-action-item action-share">
        <div class="d-flex align-items-center">
          <a class="text-primary facebook" @click="shareOnFacebook">
            <i class="ph ph-facebook-logo"></i>
          </a>
          <a class="text-primary twitter" @click="shareOnTwitter">
            <i class="ph ph-twitter-logo"></i>
          </a>
          <a class="text-primary whatsapp" @click="shareOnWhatsApp">
            <i class="ph ph-whatsapp-logo"></i>
          </a>
          <a class="text-primary telegram" @click="shareOnTelegram">
            <i class="ph ph-telegram-logo"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="blog-item small-item" v-for="item in newsPost" :key="item.id">
      <div class="blog-thumb">
        <div>
          <router-link :to="`/vacancy/${item.slug}`">
            <img class="fixed-image-list" v-if="!item.images.length" src="../../../assets/img/images/placeholder_size_1.jpg">
            <img class="fixed-image-list" v-else :src="getMainImage(item.images)" alt="">
          </router-link>
        </div>
      </div>
      <div class="blog-content">
        <div class="blog-meta">
          <ul>
            <li class="d-flex align-items-center"><i class="ph ph-calendar-blank"></i> {{ formatDate(item.created) }}</li>
          </ul>
        </div>
        <h2 class="title"><router-link :to="`/vacancy/${item.slug}`">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</router-link></h2>
      </div>
    </div>
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center mt-4">
        <li class="page-item">
          <a class="page-link"
             href="#"
             @click="changePageNews(newsCurrentPage - 1)">
            {{ $t('Previous') }}
          </a>
        </li>
        <li class="page-item" v-for="pageNumber in pageNumberNews" :key="pageNumber" :class="{active:newsCurrentPage == pageNumber || pageNumber === '....'}">
          <a class="page-link"
             href="#" @click="changePageNews(pageNumber)">{{pageNumber}}</a>
        </li>
        <li class="page-item">
          <a class="page-link"
             href="#"
             @click="changePageNews(newsCurrentPage +1)">
            {{ $t('NextNext') }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import axios from 'axios'
import getLocaleText from "../../../locales/getLocaleText";
import i18n from "@/local18";

export default {
  name: 'ContentNewsView',
  data() {
    return {
      currentUrl:window.location.href,
      News:[],
      itemPerPage:6,
      newsCurrentPage:1,
    };
  },
  created() {
    this.fetchNews();
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        this.fetchNews();
      }
    }
  },
  computed: {

    newsPost() {
      const startPage = (this.newsCurrentPage - 1) * this.itemPerPage;
      const endPage = startPage + this.itemPerPage;
      return this.News.slice(startPage, endPage);
    },
    totalNews() {
      return Math.ceil(this.News.length / this.itemPerPage)
    },
    pageNumberNews() {
      let pageNumbers = []
      if (this.totalNews <= 7) {
        for (let i = 1; i <= this.totalNews; i++) {
          pageNumbers.push(i);
        }
      } else {
        if (this.newsCurrentPage <= 4) {
          pageNumbers = [1, 2, 3, 4, 5, "....", this.totalNews];
        } else if (this.newsCurrentPage <= this.totalNews - 3) {
          pageNumbers = [1, "....", this.newsCurrentPage - 2, this.newsCurrentPage - 1, this.newsCurrentPage, this.newsCurrentPage + 1, this.newsCurrentPage + 2, "....", this.totalNews];

        } else {
          pageNumbers = [1, "....", this.totalNews - 4, this.totalNews - 3, this.totalNews - 2, this.totalNews - 1, this.totalNews]
        }
      }
      return pageNumbers;
    },
  },
  methods: {
    shareOnTwitter (){
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnFacebook(){
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnWhatsApp(){
      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    shareOnTelegram(){
      window.open(`tg://msg_url?url=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    changePageNews(page){
      if(page >=1 && page <= this.totalNews){
        this.newsCurrentPage = page;
      }
    },
    async fetchNews() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`news?lang=${local}&category=auction`);
        this.News = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    getLocaleText,
    getMainImage(images){
      let mainImage=images.find(item =>item.main_image);
      if(mainImage){
        return mainImage.image_size_1
      }
      else{
        return images[0].image_size_1
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Bu tarih dilimine göre tarihi biçimlendirir
    }
  },
}
</script>